import React from "react";

function AboutUs() {
  return (
    <>
      <div className="about-banner container-fluid">
        <div className="row">
          <div className="col-lg-12 text-sm-center text-left mb-4 py-5 col-12">
            <h1 className="title mb-2 mt-4">About Us</h1>
            <p className="col-12 col-md-12 col-lg-10 col-xl-8 para mx-auto mt-4 p-0">
              At <span className="title-span">Taskiboon Publishers</span>, we
              create user-friendly mobile applications that assist countless
              individuals in their everyday routines. Our primary goal is to
              offer superior service and deliver high-quality products. You can
              easily find our apps on the Google Play store, where they are
              available for free download.
            </p>
          </div>
        </div>
      </div>
      <div className="about-content mt-3 container-fluid">
        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <div className="about-logo mx-auto">
              <img
                className="img-fluid"
                src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Fquran-memorization-scheduler-logo.png?alt=media"
                alt="Quran Memorization Scheduler and Tracker"
              />
            </div>
            <h2 className="text-center about-logo-header mt-2">
              Quran Memorization Scheduler & Tracker
            </h2>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 text-center">
            <p className="about-para">
              Our app, the{" "}
              <strong>Quran Memorization Scheduler and Tracker</strong>, is
              designed to assist individuals in memorizing the Quran. It began
              as a simple concept to help people keep track of their
              memorization progress and stay motivated. As time passed, we
              listened to our users and expanded the app's features to cater to
              their needs. Today, our comprehensive mobile app is used by
              thousands of people worldwide. <br />
              <br />
              We take great pride in supporting the Ummah (Muslim community) at
              every stage of their Quran memorization journey. Our app is
              suitable for both beginners and advanced memorizers, providing
              valuable tools and resources to help them succeed. Whether you are
              just starting or already accomplished in Quran memorization, our
              app is here to support and guide you along the way.
            </p>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="row mt-3 mb-5">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 text-center bg-success text-light p-2 rounded about-para">
            May Allah bless everyone to become a Hafiz of the Quran.
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
