import React, { useEffect } from "react";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";

function SupportUs() {
  const copyText = (textToBeCopied) => {
    const textarea = document.createElement("textarea");
    textarea.value = textToBeCopied;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const openPlayStoreLink = () => {
    const url =
      "https://play.google.com/store/apps/details?id=com.taskiboonpublishers.quranmemorizationschedulerandtracker";
    window.open(url, "_blank", "noopener noreferrer");
  };

  useEffect(() => {
    const popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );
    popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl);
    });
  }, []);

  return (
    <>
      <div className="about-banner container-fluid">
        <div className="row">
          <div className="col-lg-12 text-sm-center text-left mb-4 py-5 col-12">
            <h1 className="title mb-2 mt-4">
              Support Quran Memorization Scheduler & Tracker
            </h1>
            <p className="col-12 col-md-12 col-lg-10 col-xl-8 para mx-auto mt-4 p-0 lead">
              Help us spread the word and empower more people to memorize the
              Quran. Your support helps us run Google Ads campaigns for our app{" "}
              <strong>Quran Memorization Scheduler & Tracker</strong>.
              Additionally, you can support us by sharing this app with your
              friends and family.
            </p>
          </div>
        </div>
      </div>
      <div className="about-content mt-3 container-fluid">
        <div className="row mt-5">
          <div className="col-lg-12 text-center">
            <div className="about-logo mx-auto">
              <img
                className="img-fluid"
                src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Fquran-memorization-scheduler-logo.png?alt=media"
                alt="Quran Memorization Scheduler and Tracker"
              />
            </div>
            <h2 className="text-center about-logo-header mt-2">
              Support Quran Memorization Scheduler & Tracker
            </h2>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-lg-2 col-md-2"></div>
          <div className="col-lg-8 col-md-8">
            <p className="mt-4">
              Empower More People to Memorize the Quran: Support Our Cause!
            </p>
            <p className="px-4 py-1 benefits-hadith">
              <b> ✦ Hadith :</b>
              <br />
              <br /> Abu Huraira (May Allah be pleased with him) reported: The
              Prophet (peace be upon him) said, “When a man dies, his deeds come
              to an end except for three things: Sadaqah Jariyah; knowledge
              which is beneficial, or a virtuous descendant who prays for him.”
            </p>
            <p className="text-center benefits-hadith-quote">
              <i>[Sahih Muslim]</i>
            </p>
            <p>
              Sadaqah Jariyah is ongoing charity (continuous rewards) received
              by us for good actions and deeds, which will not only benefit us
              in this life but will also continue to benefit us after our death,
              Inshaallah.
            </p>
            <p>
              If you teach someone to recite or memorize the Quran, every time
              they recite or recite it from memory, you will receive hasanat,
              even after your death. If your student teaches someone else to
              recite or memorize the Quran, then every time this other person
              recites or recites it from memory, you will receive hasanat from
              their recitation. Even after you have passed from this world, you
              will continue to receive hasanat until the end of time.
            </p>
            <p>Choose a method that works best for you:</p>
            <div className="accordion" id="paymentsAccordian">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. India: UPI
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#paymentsAccordian"
                >
                  <div className="accordion-body">
                    <div className="col-12">
                      <div className="upi-payment row">
                        <div className="col-lg-6">
                          <h5>1. UPI</h5>
                          <p>
                            For faster and secure payments within India, please
                            use UPI:
                          </p>
                          <p>
                            Scan the QR code or click the below button to copy
                            the UPI address:
                          </p>
                          <div className="col-12 text-center mb-3">
                            <button
                              type="button"
                              className="btn btn-primary "
                              data-bs-container="body"
                              data-bs-toggle="popover"
                              data-bs-trigger="focus"
                              data-bs-placement="top"
                              data-bs-content="copied"
                              onClick={(event) => {
                                copyText("taskiboonpublishers@icici");
                                event.stopPropagation();
                              }}
                            >
                              taskiboonpublishers@icici
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ftp_upi_qr_code.png?alt=media"
                            alt="UPI QR code"
                            className="img-fluid mb-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. Other Countries: PayPal or Binance Pay
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#paymentsAccordian"
                >
                  <div className="accordion-body">
                    <div className="col-12">
                      <p>
                        For users outside of India, we accept payments through:
                      </p>
                      <div className="upi-payment">
                        <h5>1. PayPal</h5>
                        <p>
                          Send your contribution through PayPal by clicking the
                          below link:
                        </p>
                        <div className="col-12 text-center mb-5">
                          <a
                            href="https://paypal.me/taskiboonpublishers"
                            target="_blank"
                            type="button"
                            rel="noopener noreferrer"
                            className="btn btn-primary"
                          >
                            Pay via PayPal
                          </a>
                        </div>
                      </div>
                      <div className="upi-payment row">
                        <div className="col-lg-6">
                          <h5>2. Binance Pay</h5>
                          <p>
                            Scan the QR code via Binance App or click the below
                            button to copy the Binance User Id:
                          </p>
                          <div className="col-12 text-center mb-3">
                            <button
                              className="btn btn-primary"
                              data-bs-toggle="popover"
                              data-bs-trigger="focus"
                              data-bs-placement="top"
                              data-bs-content="copied"
                              onClick={(event) => {
                                copyText("177634267");
                                event.stopPropagation();
                              }}
                            >
                              Binance ID: 177634267
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 text-center">
                          <img
                            src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Fbinance_pay_qr_code.png?alt=media"
                            alt="Binance Pay QR code"
                            className="img-fluid mb-3 rounded"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingThree">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. Spread the word
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#paymentsAccordian"
                >
                  <div className="accordion-body">
                    <div className="col-12">
                      <div className="upi-payment">
                        <div className="col-12 mx-auto">
                          <p>
                            Share the Quran Memorization Scheduler app with your
                            friends and family who might benefit from it.
                          </p>

                          <div className="col-12 text-center mb-3">
                            <button
                              type="button"
                              className="btn btn-primary "
                              data-bs-container="body"
                              data-bs-toggle="popover"
                              data-bs-placement="top"
                              data-bs-content="copied"
                              onClick={openPlayStoreLink}
                            >
                              Share the App
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 p-2 text-danger text-center mt-5">
              Your contributions, <strong>financial or by sharing</strong>, are
              deeply appreciated and will help us reach more people with the
              Quran Memorization Scheduler & Tracker app.
            </div>
          </div>
          <div className="col-lg-2 col-md-2"></div>
        </div>
        <div className="row my-5 mx-0">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 text-center bg-success text-light p-2 rounded about-para">
            May Allah bless everyone to become a Hafiz of the Quran.
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </>
  );
}

export default SupportUs;
