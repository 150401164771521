import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="mp-web-footer">
      <div className="footer mt-2 mt-lg-3">
        <div className="container-fluid">
          <div className="row col-sm-10 col-12 mx-auto ps-sm-0 pe-sm-0 bordered">
            <div className="col-12 row mx-0 d-flex d-md-none">
              <div className="col-6">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/about-us"
                    className="text-black footer-main-name link"
                  >
                    About Us
                  </Link>
                </h2>
              </div>
              <div className="col-6">
                {" "}
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/features"
                    className="text-black footer-main-name link"
                  >
                    Features
                  </Link>
                </h2>
              </div>
              <div className="col-6">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/memorization-tips"
                    className="text-black footer-main-name link"
                  >
                    Memorization Tips
                  </Link>
                </h2>
              </div>
              <div className="col-6">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/memorization-benefits"
                    className="text-black footer-main-name link"
                  >
                    Memorization Benefits
                  </Link>
                </h2>
              </div>
              <div className="col-6">
                {" "}
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/contact-us"
                    className="text-black footer-main-name link"
                  >
                    Contact Us
                  </Link>
                </h2>
              </div>
              {/* <div className="col-6">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/support-us"
                    className="text-black footer-main-name link"
                  >
                    Support Us
                  </Link>
                </h2>
              </div> */}
            </div>
            <div className="col-xl-8 col-lg-10 mx-auto d-flex flex-column flex-md-row mt-md-4 mt-lg-0 justify-content-between align-items-bottom">
              <div className="d-none d-md-block">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/about-us"
                    className="text-black footer-main-name link"
                  >
                    About Us
                  </Link>
                </h2>
              </div>
              <div className="d-none d-md-block">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/features"
                    className="text-black footer-main-name link"
                  >
                    Features
                  </Link>
                </h2>
              </div>
              <div className="d-none d-md-block">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/memorization-tips"
                    className="text-black footer-main-name link"
                  >
                    Memorization Tips
                  </Link>
                </h2>
              </div>
              <div className="d-none d-md-block">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/memorization-benefits"
                    className="text-black footer-main-name link"
                  >
                    Memorization Benefits
                  </Link>
                </h2>
              </div>
              <div className="d-none d-md-block">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/contact-us"
                    className="text-black footer-main-name link"
                  >
                    Contact Us
                  </Link>
                </h2>
              </div>
              {/* <div className="d-none d-md-block">
                <h2 className="footer-nav my-3 my-md-4 text-center">
                  <Link
                    to="/support-us"
                    className="text-black footer-main-name link"
                  >
                    Support Us
                  </Link>
                </h2>
              </div> */}
            </div>
          </div>
          <div className="row col-sm-10 col-12 mx-auto pe-sm-0 ps-sm-0 bordered mt-sm-3">
            <div className="col-md-6 col-sm-12 text-center ps-sm-0 mb-md-2">
              <div className="col-12 d-flex ps-sm-0">
                <div className="text-center mx-auto mx-lg-0">
                  <Link to="/">
                    <img
                      className="align-slef-center"
                      width="52"
                      height="52"
                      src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Fquran-memorization-scheduler-logo_52x52.png?alt=media"
                      alt="QMSAT icon"
                    />
                  </Link>
                  <h2 className="my-lg-3 ms-3 my-2 ms-lg-2 align-middle d-inline-block footer-main-name">
                    Quran Memorization Scheduler & Tracker
                  </h2>
                </div>
              </div>
              <p className="footer-text mt-2 mt-sm-3 footer-main-name">
                The one & only app for scheduling and tracking Quran
                memorization.
              </p>
              <Link className="footer-text link me-4" to="/privacy">
                Privacy Policy
              </Link>
              <Link className="footer-text link" to="/terms">
                Terms &amp; Conditions
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 text-center text-md-end align-self-center">
              <a
                href="https://play.google.com/store/apps/details?id=com.taskiboonpublishers.quranmemorizationschedulerandtracker"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-download d-inline-block mt-3 mt-lg-4 text-center order-4 order-lg-3"
              >
                Download App
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer bg-grey">
        <div className="container-fluid">
          <div className="row col-sm-12 col-12 mx-auto footer-main-box">
            <div className="col-12 text-center text-md-end ps-md-0">
              <p className="footer-text my-4">
                powered by{" "}
                <a
                  href="https://taskiboonpublishers.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Taskiboon Publishers
                </a>{" "}
                © 2020
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
