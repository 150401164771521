const config = {
    apiKey: "AIzaSyBbD_dLpKQLYFL8aIqF2fyCkcG_VIB0coI",
    authDomain: "quran-memorization-scheduler.firebaseapp.com",
    databaseURL: "https://quran-memorization-scheduler.firebaseio.com",
    projectId: "quran-memorization-scheduler",
    storageBucket: "quran-memorization-scheduler.appspot.com",
    messagingSenderId: "823981908005",
    appId: "1:823981908005:web:47aaeba1abd23b9eecdfcc",
    measurementId: "G-8PMD4FW858"
  };
  
export default config;