import Main from './components/Main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Error from './components/Error';
import Layout from './components/Layout';
import AboutUs from './components/AboutUs';
import MemorizationTips from './components/MemorizationTips';
import MemorizationBenefits from './components/MemorizationBenefits';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import Features from './components/Features';
import ContactUs from './components/ContactUs';
import ScrollToTop from './components/ScrollToTop';
import SupportUs from './components/SupportUs';

function App() {
  return (
    <div>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Main />} />
              <Route path="features" element={<Features />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="contact-us" element={<ContactUs />} />
              <Route path="privacy" element={<PrivacyPolicy />} />
              <Route path="terms" element={<TermsAndConditions />} />
              <Route path="memorization-benefits" element={<MemorizationBenefits />} />
              <Route path="memorization-tips" element={<MemorizationTips />} />
              <Route path="support-us" element={<SupportUs />} />
              <Route path="*" element={<Error />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
