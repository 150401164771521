import React from "react";

function Features() {
  return (
    <>
      <div className="features-banner container-fluid">
        <div className="row">
          <div className="col-lg-12 text-sm-center text-left mb-4 py-5 col-12">
            <h1 className="title mb-2 mt-4">
              QMS<span className="small">&</span>T's&nbsp;
              <span className="title-span">Features</span>
            </h1>
            <p className="col-12 col-md-12 col-lg-10 para mx-auto mt-4 p-0">
              <span className="title-span">
                Quran Memorization Scheduler & Tracker
              </span>
              is a unique mobile app designed to help you schedule and keep
              track of your progress in memorizing the Quran. It is the top
              choice among users who want to efficiently manage their Quran
              memorization journey.
            </p>
          </div>
        </div>
      </div>
      <div className="features-content mt-3 container-fluid">
        <div className="row features-content-container col-lg-10 col-md-12 mx-auto p-3 rounded">
          <div className="col-lg-5 col-md-6 col-12 background text-left text-md-right p-0 rounded">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_1.png?alt=media"
              alt="Quran features"
              className="col-lg-6 col-12 img-fluid d-block rounded mx-auto features-image"
            />
          </div>
          <div className="col-lg-7 col-md-6 col-12 p-0 mt-4 mt-md-0 d-flex flex-column justify-content-center">
            <div className="feature-header mx-3">
              <h2>Schedule Tasks</h2>
              <h2>Create and schedule your memorization tasks</h2>
            </div>
            <div className="feature-para m-3">
              <p>
                Effortlessly manage your Quran memorization and revision tasks
                by assigning them and finishing them within your specified time.
                Set goals for memorizing Surahs and work towards them gradually
                by assigning tasks and meeting your set deadlines.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="features-content mt-3 container-fluid">
        <div className="row features-content-container col-lg-10 col-md-12 mx-auto p-3 rounded">
          <div className="col-lg-5 col-md-6 col-12 background p-0 mb-4 mb-md-0 order-md-last rounded">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_2.png?alt=media"
              alt="Quran features"
              className="col-lg-6 col-12 d-block rounded mx-auto img-fluid features-image"
            />
          </div>
          <div className="col-lg-7 col-md-6 col-12 p-0 order-md-first d-flex flex-column justify-content-center">
            <div className="feature-header mx-3 text-left text-md-right">
              <h2>Dashboard</h2>
              <h2>Manage your scheduled memorization tasks</h2>
            </div>
            <div className="feature-para m-3 text-left text-md-right">
              <p>
                Effortlessly handle your assigned tasks in the dashboard. Stay
                informed about the time you have left to complete your scheduled
                memory tasks.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="features-content mt-3 container-fluid">
        <div className="row features-content-container col-lg-10 col-md-12 mx-auto p-3 rounded">
          <div className="col-lg-5 col-md-6 col-12 background text-left text-md-right rounded p-0">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_3.png?alt=media"
              alt="Quran features"
              className="col-lg-6 col-12 img-fluid d-block rounded mx-auto features-image"
            />
          </div>
          <div className="col-lg-7 col-md-6 col-12 p-0 mt-4 mt-md-0 d-flex flex-column justify-content-center">
            <div className="feature-header mx-3">
              <h2>Taskizy</h2>
              <h2>Smart AI</h2>
            </div>
            <div className="feature-para m-3">
              <p>
                Taskizy simplifies task assignment for you. It utilizes an
                advanced algorithm that considers your preferences to assign
                tasks accordingly. You have the option to choose from various
                categories, such as:
              </p>
              <ul>
                <li>Priority (Important surah)</li>
                <li>Size</li>
                <li>Revelation order</li>
                <li>Randomized</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="features-content mt-3 container-fluid">
        <div className="row features-content-container col-lg-10 col-md-12 mx-auto p-3 rounded">
          <div className="col-lg-5 col-md-6 col-12 background p-0 mb-4 mb-md-0 order-md-last rounded">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_4.png?alt=media"
              alt="Quran features"
              className="col-lg-6 col-12 d-block rounded mx-auto img-fluid features-image"
            />
          </div>
          <div className="col-lg-7 col-md-6 col-12 p-0 order-md-first d-flex flex-column justify-content-center">
            <div className="feature-header mx-3 text-left text-md-right">
              <h2>Tracker</h2>
              <h2>Keep track of your memorization progress</h2>
            </div>
            <div className="feature-para m-3 text-left text-md-right">
              <p>
                Track your progress in memorizing the Quran easily. You can
                monitor your progress by Aayah, Surah, or Juz. This feature
                greatly enhances and improves your memorization journey.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Features;
