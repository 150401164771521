import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark static-top mp-main-navbar shadow-sm ">
      <div className="container">
        <Link to="/">
          <img
            className="navbar-icon"
            src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Fquran-memorization-scheduler-logo_52x52.png?alt=media"
            alt="Quran Memorization Scheduler & Tracker"
            width="52"
            height="52"
          />
        </Link>
        <Link to="/" className="navbar-brand d-none d-sm-block">
          Quran Memorization Scheduler & Tracker
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
          style={{ backgroundColor: "rgba(27,31,66,0.8)" }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item py-2 py-lg-0 mx-2">
              <Link className="nav-link" to="/about-us">
                About Us
              </Link>
            </li>
            <li className="nav-item py-2 py-lg-0 mx-2">
              <Link className="nav-link" to="/features">
                Features
              </Link>
            </li>
            <li className="nav-item py-2 py-lg-0 mx-2">
              <Link className="nav-link" to="/memorization-tips">
                Memorization Tips
              </Link>
            </li>
            <li className="nav-item py-2 py-lg-0 mx-2">
              <Link className="nav-link" to="/memorization-benefits">
                Memorization Benefits
              </Link>
            </li>
            <li className="nav-item mx-2">
              <a
                className="nav-link btn-download text-center"
                href="https://play.google.com/store/apps/details?id=com.taskiboonpublishers.quranmemorizationschedulerandtracker"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download App!
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
