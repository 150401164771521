import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
// import SupportUsNotificationComponent from "./SupportUsNotification.component";

function Layout() {
  return (
    <>
      <Header />
      <div className="main-container col-12">
        <Outlet />
        <Footer />
      </div>
      {/* <SupportUsNotificationComponent /> */}
    </>
  );
}

export default Layout;
