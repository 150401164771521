import React from "react";

function MemorizationBenefits() {
  return (
    <>
      <div className="features-banner container-fluid">
        <div className="row">
          <div className="col-lg-12 text-sm-center text-left mb-4 py-5 col-12">
            <h1 className="title mb-2 mt-4">Benefits of memorizing Quran</h1>
            <p className="col-12 col-md-12 col-lg-10 para mx-auto mt-4 p-0">
              Understanding the benefits of memorizing the Quran can greatly
              enhance your memorization journey and strengthen your commitment
              to it. Here are a few benefits, some derived from hadith
              (teachings of Prophet Muhammad) and others supported by scientific
              evidence. If you have any suggestions or corrections, please feel
              free to email us at{" "}
              <a
                href="mailto:taskiboonpublishers@gmail.com?Subject=Review%20-%20Benefits%20of%20memorizing%20Quran"
                style={{ color: "#f9f9f9", textDecoration: "underline" }}
              >
                taskiboonpublishers@gmail.com
              </a>
              .{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="tips-container">
        <div className="header-1"></div>
        <div className="header-2"></div>
        <div className="tips">
          <div className="separator" style={{ clear: "both" }}>
            <a
              style={{
                display: "block",
                padding: "1em 0",
                textAlign: "center",
              }}
              href
            >
              <img
                alt=""
                border="0"
                className="col-lg-6 col-md-8 col-12 rounded"
                src="https://1.bp.blogspot.com/-BBM073lNREQ/YIPHVeoau2I/AAAAAAAAAR4/wfiY-p_yjnoGX4juX208Ezrm06TAZSbBwCLcBGAsYHQ/s400/book-1283468__340.webp"
              />
            </a>
          </div>
          <p>
            Memorizing the Quran brings great rewards, and understanding these
            benefits can greatly enhance your memorization journey. Here are
            some key advantages of memorizing the Quran:
          </p>
          <h4> 1. High status in Paradise </h4>
          <p className="px-4 py-1 benefits-hadith">
            <b> ✦ Hadith :</b>
            <br />
            <br /> Abdullah ibn Amr reported: The Prophet (peace be upon him)
            said, “It will be said to the companion of the Quran: Recite and
            ascend as you recited in the world. Verily, your rank is determined
            by the last verse you recite.”
          </p>
          <p className="text-center benefits-hadith-quote">
            <i>[Sunan al-Tirmidhi 2914]</i>
          </p>
          <p className="benefits-text">
            According to a saying of the Prophet Muhammad (peace be upon him),
            on the Day of Judgment, people will be asked to recite the Quran. As
            they begin reciting, they will ascend to the Heavens, and they will
            continue to rise until they reach their place in Paradise once they
            have finished reciting the entire Quran. This shows the immense
            honor and reward for those who have memorized the entire Quran. It's
            truly amazing to think about the height that can be reached by
            someone who has this remarkable achievement of memorizing the entire
            Quran.
          </p>
          <p className="px-4 py-1 benefits-hadith">
            <b> ✦ Hadith :</b>
            <br />
            <br />
            In another narration, the Prophet Muhammad (peace be upon him) said,
            “The one who memorizes the Quran and acts upon it will be with the
            great righteous scribes.”
          </p>
          <p className="text-center benefits-hadith-quote">
            <i>[Al-Bukhari]</i>
          </p>
          <p className="benefits-text">
            This is because the Quran is the word of Allah, and memorizing it is
            a great act of worship. It shows that the person has dedicated
            themselves to learning and understanding the Quran, and that they
            are committed to following its teachings.
          </p>
          <h4> 2. Earn Reward for Your Parents:</h4>
          <p className="benefits-text">
            When children recite the Quran and follow its teachings, they not
            only earn great rewards for themselves but also for their parents.
            Allah SWT promises special rewards, such as shining crowns and
            beautiful garments in paradise, for the parents of children who
            sincerely engage with the Quran. While we can never fully repay our
            parents for all they have done for us, it is a beautiful honor to be
            the means of elevating their status in the afterlife.
          </p>
          <p className="px-3 py-1 benefits-hadith">
            <b> ✦ Hadith :</b>
            <br />
            <br />
            The Prophet (ﷺ) said, “Whoever reads the Quran, learns it, and acts
            in accordance with it, on the Day of Resurrection, his parents will
            be given a crown to wear whose light will be like the light of the
            sun, and his parents will be given garments which far surpass
            everything that is found in this world. They will say, 'Why have we
            been given this to wear?' It will be said, 'Because your child
            learned the Quran.'”
          </p>
          <p className="text-center benefits-hadith-quote">
            <i>[Saheeh At-Targheeb]</i>
          </p>
          <p className="benefits-text">
            The hadith suggests that parents will be rewarded when their child
            memorizes the Quran, regardless of their direct involvement. This
            reward is one of the ways Allah blesses His servants. However, it's
            important to note that this hadith is a general indication and does
            not guarantee that every parent of a Quran-memorizing child will
            receive this reward. It depends on fulfilling certain conditions and
            avoiding certain prohibitions. It's not an assurance that parents
            who have a Quran-memorizing child will be exempt from punishment if
            they have committed punishable actions. Instead, it is hoped that
            they will receive this reward and that Allah will forgive them based
            on the blessing of their child's Quran memorization.
          </p>
          <h4> 3. Protection from Hellfire:</h4>
          <p className="px-4 py-1 benefits-hadith">
            <b> ✦ Hadith :</b>
            <br />
            <br />
            The Prophet Muhammad (peace be upon him) said, "The Quran will be a
            intercessor for its reciter on the Day of Resurrection."
          </p>
          <p className="text-center benefits-hadith-quote">
            <i>[Muslim]</i>
          </p>
          <p className="benefits-text">
            The hadith suggests that that the Quran will plead on behalf of the
            person who memorized it, and ask Allah to forgive them and admit
            them into Paradise.
          </p>
          <h4> 4. Improved memory and concentration:</h4>
          <p className="benefits-text">
            Memorizing the Quran is a challenging task that requires
            concentration and focus. However, the benefits go beyond just
            learning the verses. When someone commits to memorizing the Quran,
            they develop strong memory skills and enhanced concentration
            abilities. This improvement in memory and concentration doesn't only
            apply to Quranic memorization but also extends to other aspects of
            their life. It becomes easier for them to remember and recall
            information, whether it's studying for exams, remembering important
            details, or staying focused on tasks. So, by dedicating time and
            effort to memorize the Quran, individuals can experience positive
            effects on their overall memory and concentration skills, benefiting
            them in various areas of their life.
          </p>
          <h4> 5. Inner peace and tranquility:</h4>
          <p className="benefits-text">
            The Quran is a source of great peace and tranquility for those who
            recite it and reflect on its meanings. Memorizing the Quran can help
            the person to develop a deeper connection with the Quran, and to
            experience its blessings more fully.
          </p>
          <h4> 6. Increased knowledge of Islam:</h4>
          <p className="benefits-text">
            Memorizing the Quran requires the person to learn about the meanings
            of the verses. This can help the person to develop a deeper
            understanding of Islam, and to live their life in accordance with
            its teachings.
          </p>
          <p className="benefits-text">
            In addition to these, there are many other benefits to memorizing
            the Quran. It is a challenging but rewarding task, and it can bring
            great blessings to the person who undertakes it.
          </p>
          <p
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              textAlign: "center",
              color: "green",
            }}
          >
            Wish Allah bless everyone to become a Quran-e-Hafiz.
          </p>
        </div>
      </div>
    </>
  );
}

export default MemorizationBenefits;
