import React from "react";
import { Link } from "react-router-dom";

function TermsAndConditions() {
  return (
    <>
      <div className="features-banner container-fluid">
        <div className="row">
          <div className="col-lg-12 text-sm-center text-left mb-4 py-5 col-12">
            <h1 className="title mb-2 mt-4">
              Terms and Conditions for Taskiboon Publishers
            </h1>
            <p className="col-12 col-md-12 col-lg-10 para mx-auto mt-4 p-0">
              These Terms of Service is meant to understand the way that
              Taskiboon Publishers's business works and the laws that apply to
              our company. As a result, these Terms of Service help define
              Taskiboon Publishers's relationship with you as you interact with
              our services.
            </p>
          </div>
        </div>
      </div>
      <div className="terms-container">
        <div className="header-1"></div>
        <div className="header-2"></div>
        <div className="terms-content">
          <p>
            These Standard Terms and Conditions written on this webpage shall
            manage your use of our app "
            <strong>Quran Memorization Scheduler and Tracker</strong>" and
            website, accessible at{" "}
            <a href="https://quranmsat.web.app" className="link-primary">
              https://quranmsat.web.app
            </a>
            .
          </p>
          <p>
            These Terms will be applied fully and affect to your use of our app
            and any instances from this site. By using our app and our site, you
            agreed to accept all terms and conditions written in here. You must
            not use our app or website if you disagree with any of these
            Standard Terms and Conditions.
          </p>
          <h4> Intellectual Property Rights</h4>
          <p>
            Other than the content you own, under these Terms,{" "}
            <strong>Taskiboon Publishers</strong> and/or its licensors own all
            the intellectual property rights and materials contained in this app
            or website.
          </p>
          <p>
            You are granted limited license only for purposes of viewing the
            material contained on this Website.
          </p>
          <h4> Restrictions</h4>
          <p>You are specifically restricted from all of the following:</p>
          <ul>
            <li>publishing any app or website material in any other media;</li>
            <li>
              selling, sublicensing and/or otherwise commercializing any app or
              website material;
            </li>
            <li>
              publicly performing and/or showing any app or website material;
            </li>
            <li>
              using this app and website in any way that is or may be damaging
              to this app or website;
            </li>
            <li>
              using this app and website in any way that impacts user access to
              this app or website;
            </li>
            <li>
              using this app and website contrary to applicable laws and
              regulations, or in any way may cause harm to the app or website,
              or to any person or business entity;
            </li>
            <li>
              engaging in any data mining, data harvesting, data extracting or
              any other similar activity in relation to this app or website;
            </li>
            <li>
              using this app and webiste to engage in any advertising or
              marketing.
            </li>
          </ul>
          <p>
            Certain areas of this app or website are restricted from being
            access by you and Taskiboon Publishers may further restrict access
            by you to any areas of this app or website, at any time, in absolute
            discretion. Any user ID and password you may have for this app or
            websie are confidential and you must maintain confidentiality as
            well.
          </p>
          <h4> Your Content</h4>
          <p>
            In these Website Standard Terms and Conditions, "Your Content" shall
            mean any audio, video text, images or other material you choose to
            display on this Website. By displaying Your Content, you grant
            Taskiboon Publishers a non-exclusive, worldwide irrevocable, sub
            licensable license to use, reproduce, adapt, publish, translate and
            distribute it in any and all media.
          </p>
          <p>
            Your Content must be your own and must not be invading any
            third-party’s rights. Taskiboon Publishers reserves the right to
            remove any of Your Content from this Website at any time without
            notice. But note, we do not share your any information publicly on
            our site or the app, unless we have notified you regarding that.
          </p>
          <h4> Your Privacy</h4>
          <p>
            Please read{" "}
            <Link to="privacy" className="link-primary">
              Privacy Policy
            </Link>
            .
          </p>
          <h4> No warranties</h4>
          <p>
            This Website is provided "as is," with all faults, and Taskiboon
            Publishers express no representations or warranties, of any kind
            related to this Website or the materials contained on this Website.
            Also, nothing contained on this Website shall be interpreted as
            advising you.
          </p>
          <h4> Limitation of liability</h4>
          <p>
            In no event shall Taskiboon Publishers, nor any of its officers,
            directors and employees, shall be held liable for anything arising
            out of or in any way connected with your use of this app or website,
            whether such liability is under contract. Taskiboon Publishers,
            including its officers, directors and employees shall not be held
            liable for any indirect, consequential or special liability arising
            out of or in any way related to your use of this Website.
          </p>
          <h4> Indemnification</h4>
          <p>
            You hereby indemnify to the fullest extent Taskiboon Publishers from
            and against any and/or all liabilities, costs, demands, causes of
            action, damages and expenses arising in any way related to your
            breach of any of the provisions of these Terms.
          </p>
          <h4> Severability</h4>
          <p>
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
          <h4> Variation of Terms</h4>
          <p>
            Taskiboon Publishers is permitted to revise these Terms at any time
            as it sees fit, and by using this app or website you are expected to
            review these Terms on a regular basis.
          </p>
          <h4> Assignment</h4>
          <p>
            The Taskiboon Publishers is allowed to assign, transfer, and
            subcontract its rights and/or obligations under these Terms without
            any notification. However, you are not allowed to assign, transfer,
            or subcontract any of your rights and/or obligations under these
            Terms.
          </p>
          <h4> Entire Agreement</h4>
          <p>
            These Terms constitute the entire agreement between Taskiboon
            Publishers and you in relation to your use of this Website, and
            supersede all prior agreements and understandings.
          </p>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
