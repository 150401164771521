import React, { useEffect, useState } from "react";
import Odometer from "react-odometerjs";

function Main() {
  const LAST_INSTALL_COUNT = 19937;
  const CURRENT_INSTALL_COUNT = 20940;
  const [reviews] = useState([
    {
      reviewContent:
        "Alhamdulillah! Atlast I found an app that will simply motivate us to memorize the Quran. And best thing in this app is that we can check our Quran memorization progress, like how many verses we have memorized, how many are in progress etc., simply I will rate 5* for this.",
      reviewedDate: "28 September 2020",
      reviewedBy: "Bazi Shaik",
    },
    {
      reviewContent:
        "Amazing jazakallahukhair 💖 highly recommended for those who want read every day, or even if in just for Ramadhan & especially for the Hafiz.",
      reviewedDate: "16 April 2022",
      reviewedBy: "Khan Ejaj",
    },
    {
      reviewContent:
        "It's been so long since I started looking for an app that can help me set a plan for memorization, this app is exactly what I wanted. I really appreciate it and may Allah bless you.",
      reviewedDate: "11 August 2022",
      reviewedBy: "Hibah Osailan",
    },
    {
      reviewContent:
        "Great app Jazakallahu khairan ❤️ This app motivates me to memorize Quran Karim.",
      reviewedDate: "30 December 2023",
      reviewedBy: "Saadatullah Ahmadi",
    },
    {
      reviewContent:
        "I recommend this app, it is more than amazing, it shows your progress it is quite encouraging on memorization. 💖💖💖💖💖",
      reviewedDate: "9 January 2024",
      reviewedBy: "Maryam Suleiman",
    },
  ]);

  const [installCount, setInstallCount] = useState(LAST_INSTALL_COUNT);
  useEffect(() => {
    const timeoutId = setTimeout(() => setInstallCount(CURRENT_INSTALL_COUNT), 500);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <div className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 text-start mb-2 md-mb-0 order-2 order-lg-1 flex-wrap main-box p-0">
              <h1 className="mt-2 mt-lg-5 banner-header mx-auto mx-lg-0">
                The <span className="banner-header1">Most Popular</span>
                <br />
                Quran Memorization Tracker App!
              </h1>
              <p className="banner-para">
                Easily schedule your memorization tasks and track your
                memorization progress with this app.
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.taskiboonpublishers.quranmemorizationschedulerandtracker"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-download d-inline-block mt-3 mt-lg-4 text-center order-4 order-lg-3"
              >
                Download App
              </a>
            </div>
            <div className="col-lg-7 text-center order-1 order-lg-2 row p-0 m-0">
              <div className="col-4 p-0 justify-content-end">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_2.png?alt=media"
                  className="col-10 p-0"
                  alt="QMSAT feature"
                />
              </div>
              <div className="col-4 p-0">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_4.png?alt=media"
                  className="col-12 p-0"
                  alt="QMSAT feature"
                />
              </div>
              <div className="col-4 p-0 justify-content-end">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/quran-memorization-scheduler.appspot.com/o/hosting%2Ffeature_1.png?alt=media"
                  className="col-10 p-0"
                  alt="QMSAT feature"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-dnldcounter">
        <div className="container">
          <div className="col-xl-8 col-lg-10 col-12 text-center mx-auto">
            <h2 className="dnld-text mb-4">
              <span className="dnld-text2">Thank you</span>&nbsp;for the support
            </h2>
            <p className="dnld-para">
              We greatly appreciate your support for Quran Memorization
              Scheduler & Tracker. Your contribution is vital to the success of
              our app and the progress we're making. If you find the app
              valuable and beneficial, we kindly request you to show your
              support by leaving a review on the Play Store. Additionally, we
              encourage you to share this product with your loved ones, friends,
              and others who may benefit from it. Together, we can make a
              positive impact and help more people in their Quran memorization
              journey. Thank you for your support and encouragement.
            </p>
          </div>
          <div className="col-lg-6 col-md-8 col-sm-12 col-12 mx-auto mt-2 mb-4">
            <div className="row">
              <div className="col-6 text-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.taskiboonpublishers.quranmemorizationschedulerandtracker"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-download d-inline-block mt-3 text-center"
                >
                  Review
                </a>
              </div>
              <div className="col-6 text-center">
                <a
                  href="https://play.google.com/store/apps/details?id=com.taskiboonpublishers.quranmemorizationschedulerandtracker"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-download d-inline-block mt-3 text-center"
                >
                  Share
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 text-center py-4">
            <Odometer
              value={installCount}
              format="(,ddd)"
              duration={5000}
              theme="car"
              animation="count"
            />
            <p className="dnld-para text-center mt-2">downloads till now!</p>
          </div>
        </div>
      </div>
      <div className="home-carousel mb-5">
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel"
          data-bs-interval="5000"
        >
          <div className="carousel-indicators">
            {reviews.map((review, index) => (
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
              ></button>
            ))}
          </div>
          <div className="carousel-inner">
            {reviews.map((review, index) => (
              <div className={`carousel-item ${index === 0 ? "active" : ""}`}>
                <div className="carousel-inner-image"></div>
                <div className="carousel-caption text-start">
                  <h2 className="caption1">{review?.reviewContent}</h2>
                  <p className="para1">{review?.reviewedBy}</p>
                  <p className="para2">{review?.reviewedDate}</p>
                </div>
              </div>
            ))}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon"></span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon"></span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Main;
