import React from 'react'
import { Link } from 'react-router-dom'

function Error() {
  return (
    <div className="container-fluid py-5">
      <div className="text-center text-danger p-lg-4 p-2 fw-bold">Page you requested not found. Go to  <Link to="/" className="link-primary">home page</Link></div>
    </div>
  )
}

export default Error