import React, { useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";

import config from "../config";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [type, setType] = useState("Suggestion");
  const [isTouched, setIsTouched] = useState(false);
  const app = initializeApp(config);
  const db = getDatabase(app);

  const handleSubmit = (event) => {
    setIsTouched(true);
    let isValid = true;
    event.preventDefault();
    if (name === null || name === "") {
      isValid = false;
    } else if (name.length < 6 || name.length > 30) {
      isValid = false;
    } else if (!name.match(/^[A-Z a-z]+$/)) {
      isValid = false;
    }
    if (email === null || email === "") {
      isValid = false;
    } else if (email.length > 100) {
      isValid = false;
    } else if (!email.match(/\S+@\S+\.\S+/)) {
      isValid = false;
    }
    if (message === null || message === "") {
      isValid = false;
    } else if (message.length < 10 || message.length > 10000) {
      isValid = false;
    }
    if (isValid) {
      saveMessage(name, email, type, message);
    }
  };

  const saveMessage = (name, email, type, message) => {
    set(ref(db, "contact-forms/" + btoa(email)), {
      formid: btoa(email),
      username: name,
      email: email,
      type: type,
      message: message,
    })
      .then((snapshot) => {
        setName("");
        setType("Suggestion");
        setEmail("");
        setMessage("");
        document.getElementById(
          "alertmessage"
        ).innerHTML = `<div class="alert alert-success alert-dismissible fade show">
            <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
            <strong>Success!</strong> Your message has been sent. You will receive a response from us shortly.
          </div>`;
      })
      .catch((error) => {
        if (error.code === "PERMISSION_DENIED") {
          document.getElementById(
            "alertmessage"
          ).innerHTML = `<div class="alert alert-danger alert-dismissible fade show">
                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <strong>Failed!</strong> Please wait for the response from us if you have already sent a message.
              </div>`;
        } else {
          document.getElementById(
            "alertmessage"
          ).innerHTML = `<div class="alert alert-danger alert-dismissible fade show">
                <button type="button" class="btn-close" data-bs-dismiss="alert"></button>
                <strong>Failed!</strong> Your request can not be processed. Please try again later.
              </div>`;
        }
      });
  };

  return (
    <>
      <div className="features-banner container-fluid">
        <div className="row">
          <div className="col-lg-12 text-center mb-4 py-5 col-12">
            <h1 className="title mb-2 mt-4">Contact Us</h1>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-12 col-lg-4">
            <div className="">
              <h2 className="mt-4">
                CONTACT
                <br />
                <u>US</u>
              </h2>
              <div className="pt-2 mt-3">
                We value your feedback and suggestions. Whether you have
                positive comments, helpful feedback, or noticed any issues or
                bugs in the app, we want to hear from you. Feel free to share
                your thoughts, concerns, or anything that comes to mind
                regarding the app. We will make it a priority to respond to you
                as soon as possible using the email address you provided. Your
                input is important to us, and we appreciate your engagement.
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <form onSubmit={handleSubmit}>
              <div className="contact-container col-lg-10 col-12 mx-auto">
                <div id="alertmessage"></div>
                <div className="form-floating mb-3 mt-3">
                  <input
                    id="name"
                    className="form-control"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Enter your name"
                  />
                  <label htmlFor="name">Name</label>
                  {isTouched && (name === null || name === "") ? (
                    <div>
                      <small className="text-danger">
                        Name can not be blank
                      </small>
                    </div>
                  ) : (
                    <>
                      {isTouched && !name.match(/^[A-Z a-z]+$/) ? (
                        <div>
                          <small className="text-danger">
                            Name must only contain alpha characters
                          </small>
                        </div>
                      ) : (
                        <>
                          {isTouched &&
                          (name.length < 6 || name.length > 30) ? (
                            <div>
                              <small className="text-danger">
                                Name must be of valid length 6 - 30 characters
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="form-floating mb-3 mt-3">
                  <input
                    id="email"
                    className="form-control"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email"
                  />
                  <label htmlFor="email">Email</label>
                  {isTouched && (email === null || email === "") ? (
                    <div>
                      <small className="text-danger">
                        Email can not be blank
                      </small>
                    </div>
                  ) : (
                    <>
                      {isTouched && !email.match(/\S+@\S+\.\S+/) ? (
                        <div>
                          <small className="text-danger">
                            Enter valid email ID
                          </small>
                        </div>
                      ) : (
                        <>
                          {isTouched && email.length > 100 ? (
                            <div>
                              <small className="text-danger">
                                Email must not exceed 100 characters
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="form-floating mb-3 mt-3">
                  <select
                    id="type"
                    className="form-select"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="Suggestion">Suggestion</option>
                    <option value="Feature">New feature</option>
                    <option value="Bug">Bug/issue</option>
                    <option value="General">General</option>
                  </select>
                  <label htmlFor="type" className="form-label">
                    Select type
                  </label>
                </div>
                <div className="form-floating mb-3 mt-3">
                  <textarea
                    id="message"
                    className="form-control"
                    rows="5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <label htmlFor="message">Message</label>
                  {isTouched && (message === null || message === "") ? (
                    <div>
                      <small className="text-danger">
                        Message can not be blank
                      </small>
                    </div>
                  ) : (
                    <></>
                  )}
                  {isTouched &&
                  message != null &&
                  message !== "" &&
                  (message.length < 10 || name.length > 10000) ? (
                    <div>
                      <small className="text-danger">
                        Message should be have min 10 and max of 10000
                        characters
                      </small>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <input
                  type="submit"
                  className="btn btn-download mt-3 mt-lg-4 text-center float-end"
                  value="Send Message"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;
